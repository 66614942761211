import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Fade from 'react-reveal'

import Layout from '../components/layout'
import Head from '../components/head'
import ListItem from '../components/listItem'
import styles from './games.module.scss'

const GamesPage = () => {
    const { allContentfulGame } = useStaticQuery(graphql`
        query {
            allContentfulGame(sort: {
                fields: [publishedDate]
                order: [DESC]
            }) {
                nodes {
                    title
                    slug
                    publishedDate(formatString: "DD-MM-YYYY")
                    linkToPlay
                    description
                    previewImage {
                        title
                        fluid(maxWidth: 600) {
                            ...GatsbyContentfulFluid
                        }
                    }
                    gif {
                        file { url }
                    }
                }
            }
        }
    `)
    const games = allContentfulGame.nodes

    return (
        <Layout>
            <Head title="Games" />
            <p>Here are some of my humble game projects, or projects where I contributed a major part.
            Most of them are made with Unity and Blender.
            </p>
            <p>Click on an image to see a gameplay preview.</p>
            
            <div className={styles.games}>
                {
                    games.map((game, index) => {
                        const gif = game.gif ? game.gif.file.url : null
                        return (
                            <Fade left key={index} distance={'50%'} duration={500} >
                                <ListItem  {...game} gif={gif} />
                            </Fade>
                        )
                    })
                }
            </div>
        </Layout>
    )
}

export default GamesPage
